body {
  background-color: black;
  color: #a8a8a8;
  margin: 0;
  font-family: "Montserrat", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.wordPressCol-1 {
  width: 8.33%;
}
.wordPressCol-2 {
  width: 16.66%;
}
.wordPressCol-3 {
  width: 25%;
}
.wordPressCol-4 {
  width: 33.33%;
}
.wordPressCol-5 {
  width: 41.66%;
}
.wordPressCol-6 {
  width: 50%;
}
.wordPressCol-7 {
  width: 58.33%;
}
.wordPressCol-8 {
  width: 66.66%;
}
.wordPressCol-9 {
  width: 75%;
}
.wordPressCol-10 {
  width: 83.33%;
}
.wordPressCol-11 {
  width: 91.66%;
}
.wordPressCol-12 {
  width: 100%;
}
.paddingTop200 {
  padding-top: 200px;
}

[class*="wordPressCol-"] {
  float: left;
}

.wordPressCol-12--half {
  width: 50%;
  margin: auto;
  float: none;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="wordPressCol-"] {
    width: 100%;
  }
  .paddingTop200 {
    padding-top: 0;
  }
}
